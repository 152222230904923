.navbar-brand {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 1rem;
}

.navbar-brand-text {
    color: $body-color;

    .sidebar-minimized & {
        display: none;
    }
}
