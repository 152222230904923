.table[data-module='datagrid'] {
    tr:hover {
        cursor: default;

        td {
            cursor: default;
        }
    }

    tr > td[data-editable] {
        position: relative;

        &::before {
            border: 5px solid transparent;
            border-top: 3px solid $blue;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            animation-name: spin;
            animation-duration: 2s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
            position: absolute;
            right: 1.5rem;
            top: 0.5rem;
            content: '';
            display: block;
            opacity: 0;
            z-index: 10;
            pointer-events: none;
            transition: $base-animation-duration $base-animation-timing;
        }

        &::after {
            width: 12px;
            height: 12px;
            font-family: $fa-style-family;
            content: '\f044';
            display: inline-block;
            margin-left: map_get($spacers, 2);
            opacity: 0;
            transition: $base-animation-duration $base-animation-timing;
        }

        &:hover {
            cursor: pointer;

            &::after {
                opacity: 1;
            }
        }

        &.is-loading::before {
            opacity: 1;
        }

        &.has-focus {
            padding-top: 0;
            padding-bottom: 0;

            &::after {
                display: none;
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(720deg);
    }
}
