.supported-by {
    margin-top: 1.5em;

    .sectigo {
        display: inline-block;
        position: relative;
        margin: 0 6px;
    }

    .sectigo {
        background: url('/assets/images/logos/sectigo_trust_seal_lg.png') no-repeat;
        background-size: 100%;
        width: 104px;
        height: 40px;
    }
}
