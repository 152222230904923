.browser-message {
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1100;
    position: fixed;
    opacity: 0;
    transform: translateY(100px);
    transition: 400ms ease-in-out;

    &.show {
        transform: translateY(0);
        opacity: 1;
    }
}
