.app-header {
    .form-inline {
        .form-control {
            min-width: 300px;
        }

        .btn {
            position: absolute;
            right: 0.5rem;
            top: 0;
            font-size: 1.2rem;
            line-height: 1;
            color: $gray-400;
            background: transparent;
        }
    }

    .dropdown-toggle {
        i {
            vertical-align: middle;
        }
    }

    .navbar-nav {
        .active {
            font-weight: bold;
            position: relative;

            &::after {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: $gray-200;
                content: '';
                display: block;
                height: 1px;
            }
        }
    }

    .nav-link {
        &.user {
            display: inline-flex;
            height: 54px;
            align-items: center;
            background-color: #f3f3f3;
            color: $gray-700;
            padding: 0 15px;
            min-width: 184px;
        }
    }

    .dropdown-item {
        min-width: 150px;
    }
}
