.page-error {
    .navbar-brand {
        margin-right: 0;
    }

    h1,
    p {
        text-align: center !important;
    }

    h1 {
        font-size: 42px;
        font-family: Open Sans, sans-serif;
        font-weight: 500 !important;
    }

    p {
        font-size: 1.1rem;
    }

    .row {
        position: relative;
        z-index: 2;
    }

    .logo-icon {
        display: none;
        z-index: 1;
        position: absolute;
        left: -150px;
        top: -150px;
        opacity: 0.1;
        width: 850px;
        height: auto;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .btn {
        color: $secondary;
        background-color: $supportYellow;
        padding: 0 30px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 20px 0;
        position: relative;
        border-radius: 5px;
        border: 0;
        font-weight: 500;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
        transition: all 0.3s;

        &:hover {
            color: $supportYellow;
            background-color: $secondary;
        }
    }
}
