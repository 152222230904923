// Variable overrides
// Note: most of the styling can be done simply via variable overrides

$font-size-base: 0.875rem;

$primary: #00698f;
$secondary: #555;
$info: #00afaa;
$mint: #4eaca9;

$offWhite: #faf9f6;

$supportBlue: #173f5f;
$supportYellow: #f6d55c;
$supportRed: #ff4438;

$text-muted: #666; // https://dequeuniversity.com/rules/axe/4.7/color-contrast

//$input-border-radius: 0;
//$btn-border-radius: 0;
$btn-font-weight: 600;
//$alert-border-radius: 0;

$headings-font-family: 'Open Sans', sans-serif;
$headings-font-weight: 700;

$input-bg: #fff;
$input-color: #495057;
$custom-select-color: #212529;
$input-border-color: #ccc;

// $card-cap-bg: #f3f3f3;
$card-border-radius: 0;
$table-cell-padding: 0.35rem 1rem;

$enable-rounded: true;

$sidebar-nav-link-active-color: #99e4ff;
$sidebar-nav-link-active-bg: transparent;
$sidebar-nav-link-active-icon-color: $sidebar-nav-link-active-color;

// Animations
$base-animation-duration: 150ms;
$base-animation-timing: cubic-bezier(0.55, 0, 0.1, 1); // Google swift bezier

$grid-breakpoints: (
    xs: 0,
    xm: 380px,
    sx: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
