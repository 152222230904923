.btn:not(.btn-link) {
    font-family: $headings-font-family;
}

.btn-discrete {
    background: #45515b;
    color: $white;
    border: 1px solid $input-border-color;
}

.btn-primary {
    color: $white;
    background-color: $supportBlue;
}

.btn {
    // To properly align svg icons with text
    display: inline-flex;
    align-items: center;

    i,
    svg {
        pointer-events: none;
    }
}

td > .btn-sm {
    min-width: 81px;
    text-align: left;
}
