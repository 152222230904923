.form-control-label {
    &.required::after {
        content: ' *';
    }
}

textarea.form-control {
    min-height: 115px;
    box-sizing: content-box;
    width: calc(100% - ($input-padding-x * 2));
}

.has-error .form-control,
.has-error .choices__inner,
textarea.parsley-error,
select.parsley-error,
input.parsley-error {
    border: 1px solid $red;
}

.form-control {
    &[data-cardtype] {
        background-position: calc(100% - 12px) center;
        background-repeat: no-repeat;
        background-size: 25px;
        padding-right: 50px;
    }

    &[data-cardtype='visa'] {
        background-image: url('/assets/images/icons/visa-secure.svg');
    }

    &[data-cardtype='mastercard'] {
        background-image: url('/assets/images/icons/mastercard-id-check.svg');
        background-size: 30px;
    }
}

.error-message {
    color: $red;
    display: block;
    padding-top: 0.25rem;
    width: 100%;
}

.text-muted.reached-max {
    color: $red !important;
}

.is-invalid + .error-message {
    font-size: 80%;
}

.choices + [data-charge-date] {
    display: none;
}

select.custom-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: $input-border-radius;
    text-indent: 0.01px;
    text-overflow: '';
}

.form-group.form-section {
    padding-top: 1rem;

    @include media-breakpoint-up(md) {
        padding-top: 1.5rem;
    }
}

.choices {
    //.choices__inner {
    //    background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
    //    background-size: $custom-select-bg-size;
    //}
    margin-bottom: 0;

    .error-message {
        position: absolute;
        top: 100%;
        left: 0;
        // Make default error-message invisible and use DOM mutation observer to show an alternative message
        visibility: hidden;
    }

    & ~ .error-message {
        font-size: 80%;
    }

    .choices__inner {
        border-radius: $border-radius-sm;
    }
}

.js select[data-module='choices'] {
    position: absolute;
    opacity: 0;
    left: -999em;
}

.form-filter {
    > div[id],
    div[col] {
        @include make-row;
    }

    .card-body {
        padding-top: 1rem;
        padding-bottom: 0;
    }

    .form-group.align-middle > .form-check {
        margin-top: 35px;
    }

    .form-group:not(.align-middle) > .form-check {
        display: flex;
        align-items: flex-end;
        height: 100%;

        .form-check-input {
            bottom: 12px;
        }
    }

    .card-footer {
        .custom-select {
            max-width: 130px;
        }
    }

    div[data-module='datepicker'] {
        .form-inline {
            display: none !important;
        }

        .form-control-label {
            display: none;
        }

        .has-datepicker > .flatpickr-input + div,
        .has-datepicker + .form-group {
            display: none !important;
        }
    }

    .form-group.col-12:not(.has-datepicker) {
        @include make-col(6);

        .form-control-label {
            display: none;
        }
    }

    .form-group.has-datepicker {
        margin-bottom: 0;
    }
}

// Exception to fix double error message bug on processor account form
label[for='processor_account_form_preferred'] {
    .invalid-feedback {
        display: none !important;
    }
}

.display-block {
    display: block;
}

[data-disabled] {
    pointer-events: none !important;
    opacity: 0.5 !important;
}

.form-group {
    margin-bottom: 0.75rem;
    position: relative;

    label {
        margin-bottom: 0.25rem;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;

        label {
            margin-bottom: 0.5rem;
        }
    }
}

//.form-group {
//    margin-bottom: 0.5rem;
//    position: relative;
//
//    &:not(.form-section) > label {
//        margin-bottom: 0;
//        background: #fff;
//        border-radius: 5px;
//        left: 0.5rem;
//        position: relative;
//        top: 0.65rem;
//        z-index: 2;
//    }
//
//    @include media-breakpoint-up(md) {
//        margin-bottom: 1rem;
//
//        label {
//            margin-bottom: 0.5rem;
//        }
//    }
//}
