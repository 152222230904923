.alert {
    &.align-icon {
        padding-left: 40px;

        i {
            position: absolute;
            left: 15px;
            top: 15px;
        }
    }
}
