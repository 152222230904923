/*===============================
=            Choices            =
===============================*/

// Import variables
@import '../../00-settings/_settings';

$choices-selector: 'choices' !default;
$choices-font-size-lg: 16px !default;
$choices-font-size-md: 14px !default;
$choices-font-size-sm: 12px !default;
$choices-guttering: 8px !default;
$choices-border-radius: 0 !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: $primary !default;
$choices-bg-color-disabled: #eaeaea !default;
$choices-bg-color-dropdown: $input-bg !default;
$choices-text-color: #fff !default;
$choices-keyline-color: $input-border-color !default;
$choices-primary-color: $primary !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-icon-path: '/assets/images/icons' !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;

.#{$choices-selector} {
    position: relative;
    margin-bottom: $choices-guttering;
    font-size: $choices-font-size-lg;
    &:focus {
        outline: none;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.is-disabled {
        .#{$choices-selector}__inner,
        .#{$choices-selector}__input {
            background-color: $choices-bg-color-disabled;
            cursor: not-allowed;
            user-select: none;
        }
        .#{$choices-selector}__item {
            cursor: not-allowed;
        }
    }
}
.#{$choices-selector}[data-type*='select-one'] {
    cursor: pointer;
    .#{$choices-selector}__inner {
        padding-bottom: 2px;
        padding-top: 2px;
    }
    .#{$choices-selector}__input {
        display: block;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid $choices-keyline-color;
        background-color: $input-bg;
        margin: 0;

        .darkmode & {
            background-color: #515b65;
        }
    }
    .#{$choices-selector}__button {
        background-image: url($choices-button-icon-path + '/cross-inverse.svg');
        padding: 0;
        background-size: 8px;
        height: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        margin-right: 25px;
        height: 20px;
        width: 20px;
        border-radius: 10em;
        opacity: 0.5;
        &:hover,
        &:focus {
            opacity: 1;
        }
        &:focus {
            box-shadow: 0px 0px 0px 2px $choices-highlight-color;
        }
    }
    &:after {
        content: '';
        height: 0;
        width: 0;
        border-style: solid;
        border-color: $custom-select-color transparent transparent transparent;
        border-width: 5px;
        position: absolute;
        right: 11.5px;
        top: 50%;
        margin-top: -2.5px;
        pointer-events: none;
    }
    &.is-open:after {
        border-color: transparent transparent $custom-select-color transparent;
        margin-top: -7.5px;
    }
    &[dir='rtl'] {
        &:after {
            left: 11.5px;
            right: auto;
        }
        .#{$choices-selector}__button {
            right: auto;
            left: 0;
            margin-left: 25px;
            margin-right: 0;
        }
    }
}

.#{$choices-selector}[data-type*='select-multiple'],
.#{$choices-selector}[data-type*='text'] {
    .#{$choices-selector}__inner {
        cursor: text;
    }
    .#{$choices-selector}__button {
        position: relative;
        display: inline-block;
        margin-top: 0;
        margin-right: -(calc($choices-button-offset/2));
        margin-bottom: 0;
        margin-left: $choices-button-offset;
        padding-left: $choices-button-offset * 2;
        border-left: 1px solid darken($choices-primary-color, 10%);
        background-image: url($choices-button-icon-path + '/cross.svg');
        background-size: $choices-button-dimension;
        width: $choices-button-dimension;
        line-height: 1;
        opacity: 0.75;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}

.#{$choices-selector}__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: $input-bg;
    padding: 0 7.5px 0;
    border: 1px solid $input-border-color;
    border-radius: $choices-border-radius;
    font-size: $choices-font-size-md;
    min-height: 35px;
    overflow: hidden;

    .darkmode & {
        background-color: #515b65;
        border-color: #999;
        color: #fff;
    }

    .is-focused &,
    .is-open & {
        // border-color: darken($choices-keyline-color, 15%);
        border-color: $input-focus-border-color;
        outline: 0;

        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }
    }
    .is-open & {
        border-radius: $choices-border-radius $choices-border-radius 0 0;
    }
    .is-flipped.is-open & {
        border-radius: 0 0 $choices-border-radius $choices-border-radius;
    }
}

.#{$choices-selector}__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.#{$choices-selector}__list--single {
    display: inline-block;
    padding: 4px 16px 4px 4px;
    width: 100%;
    [dir='rtl'] & {
        padding-right: 4px;
        padding-left: 16px;
    }
    .#{$choices-selector}__item {
        width: 100%;
    }
}

.#{$choices-selector}__list--multiple {
    display: inline;
    .#{$choices-selector}__item {
        display: inline-block;
        vertical-align: middle;
        border-radius: $choices-border-radius-item;
        padding: 4px 10px;
        font-size: $choices-font-size-sm;
        font-weight: 500;
        margin-right: 3.75px;
        margin-bottom: 4px;
        margin-top: 3px;
        background-color: $choices-primary-color;
        border: 1px solid darken($choices-primary-color, 5%);
        color: #fff;
        word-break: break-all;

        &[data-deletable] {
            padding-right: 5px;
        }
        [dir='rtl'] & {
            margin-right: 0;
            margin-left: 3.75px;
        }

        &.is-highlighted {
            background-color: darken($choices-primary-color, 5%);
            border: 1px solid darken($choices-primary-color, 10%);

            .darkmode & {
                background-color: $gray-700;
            }
        }

        .is-disabled & {
            background-color: darken($choices-disabled-color, 25%);
            border: 1px solid darken($choices-disabled-color, 35%);
        }
    }
}

.#{$choices-selector}__list--dropdown {
    display: none;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: $choices-bg-color-dropdown;
    border: 1px solid $choices-keyline-color;
    top: 100%;
    margin-top: -1px;
    border-bottom-left-radius: $choices-border-radius;
    border-bottom-right-radius: $choices-border-radius;
    overflow: hidden;
    word-break: break-all;

    .darkmode & {
        background-color: #515b65;
        border-color: #999;
        color: #fff;
    }

    &.is-active {
        display: block;
    }
    .is-open & {
        border-color: darken($choices-keyline-color, 15%);
    }
    .is-flipped & {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: -1px;
        border-radius: 0.25rem 0.25rem 0 0;
    }
    .#{$choices-selector}__list {
        position: relative;
        max-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
    }
    .#{$choices-selector}__item {
        position: relative;
        padding: 10px;
        font-size: $choices-font-size-md;
        [dir='rtl'] & {
            text-align: right;
        }
    }
    .#{$choices-selector}__item--selectable {
        @media (min-width: 640px) {
            // padding-right: 100px;
            &:after {
                content: attr(data-select-text);
                font-size: $choices-font-size-sm;
                opacity: 0;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
            [dir='rtl'] & {
                text-align: right;
                padding-left: 100px;
                padding-right: 10px;
                &:after {
                    right: auto;
                    left: 10px;
                }
            }
        }

        &.is-highlighted {
            background-color: mix(#000000, #ffffff, 5%);

            .darkmode & {
                background-color: $gray-700;
            }

            &:after {
                opacity: 0.5;
            }
        }
    }
}

.#{$choices-selector}__item {
    cursor: default;
}
.#{$choices-selector}__item--selectable {
    cursor: pointer;
}
.#{$choices-selector}__item--disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
    display: none;
}

.#{$choices-selector}__heading {
    font-weight: 600;
    font-size: $choices-font-size-sm;
    padding: 10px;
    border-bottom: 1px solid lighten($choices-keyline-color, 10%);
    color: lighten(#333, 30%);
}

.#{$choices-selector}__button {
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.#{$choices-selector}__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: #ffffff;
    font-size: $choices-font-size-md;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 6px 2px 6px 0;

    // Overrule min-width when dealing with a text input
    input[type='text'][data-module='choices'] ~ & {
        min-width: 50px !important;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
    }

    &:focus {
        outline: 0;
    }

    [dir='rtl'] & {
        padding-right: 2px;
        padding-left: 0;
    }

    .darkmode & {
        background-color: #515b65;
        border-color: #999;
        color: #fff;

        &::placeholder {
            color: #acb4bc;
        }
    }
}

.#{$choices-selector}__placeholder {
    opacity: 0.5;
}

/*=====  End of Choices  ======*/
