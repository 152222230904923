/* stylelint-disable no-empty-source */

.general-info {
    .card-body {
        .row {
            .form-group {
                margin-bottom: 0;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .row + .row {
            margin-top: 1.5rem;
        }
    }
}
