$accordion-transition: all $base-animation-duration $base-animation-timing;

.accordion-item {
    .card {
        border-radius: $border-radius-sm;
    }

    .collapse {
        opacity: 1;
        max-height: 999em;
        transition: $accordion-transition;
        visibility: visible;

        img {
            border: 1px solid #ccc;
            max-width: 100%;
            height: auto;
            object-fit: contain;
            margin: 1rem 0 0;
        }
    }

    .collapse:not(.show) {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        display: block;
    }
}

.accordion-button {
    font-weight: 600;
    color: $body-color;
    display: flex;
    align-items: center;
    transition: $accordion-transition;

    &::before {
        content: '+';
        font-size: 1.5rem;
        display: inline-block;
        margin-right: 0.5rem;
        position: relative;
        top: -1px;
        line-height: 1;
        transition: $accordion-transition;
        min-width: 18px;
    }

    // Active state
    &.show {
        background-color: $white;

        &::before {
            content: '-';
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: $white;
    }
}
