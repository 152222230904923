.steps {
    list-style: none;
    padding-left: 0;
    counter-reset: custom-counter;

    > li {
        counter-increment: custom-counter;
        margin-bottom: 0.5rem;

        &::before {
            width: 24px;
            height: 24px;
            text-align: center;
            content: counter(custom-counter);
            color: $white;
            background: $blue;
            border-radius: 1rem;
            margin-right: 0.5rem;
            line-height: 1.2;
            display: inline-block;
            padding: 0.25rem 0.45rem;
            font-weight: bold;
        }
    }
}
