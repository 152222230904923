$fp-preview-width: 200px;
$fp-preview-height: 200px;
$fp-preview-img-width: 150px;
$fp-preview-border-color: #ccc;
$fp-output-prefix: data-fp-output-prefix;

/**
 * File-picker
 * Note this should be a <label> element
 */
.fp-container {
    display: block;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;

    input[type='file'] {
        width: 100%;
        position: absolute;
        top: 0;
        opacity: 0.001;
        pointer-events: none;
    }
}

/**
 * Output element, used for displaying the selected filename
 */
.fp-output {
    font-weight: normal;
    padding-top: map_get($spacers, 2);
    display: block;

    &:empty {
        display: none;
    }

    &:not(:empty) {
        &::before {
            content: attr($fp-output-prefix);
        }
    }
}
