.dashboard {
    .input-group {
        min-width: 455px;
    }

    .input-group-prepend[id] {
        padding-top: 7px;
    }

    .chart-period {
        text-transform: capitalize;
    }
}


.dashboard-cards {
    .card.bg-info {
        .progress {
            background: lighten($info, 12%);
        }

        .progress-bar {
            background: darken($info, 10%);
        }
    }

    .card.bg-warning {
        .progress {
            background: lighten($warning, 12%);
        }

        .progress-bar {
            background: darken($warning, 10%);
        }
    }

    .card.bg-danger {
        .progress {
            background: lighten($danger, 12%);
        }

        .progress-bar {
            background: darken($danger, 10%);
        }
    }
}
