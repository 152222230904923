@import "~flatpickr/dist/flatpickr";

.form-group.has-timepicker,
.form-group.has-datepicker {
    position: relative;

    &::after {
        font-family: $fa-style-family;
        content: '\f073';
        position: absolute;
        right: 2rem;
        top: 2.4rem;
    }
}

// Nested datepicker (e.g. Invoice - created period date range)
.form-group {
    .form-group.has-timepicker,
    .form-group.has-datepicker {
        &::after {
            top: 0.5rem;
        }
    }
}

.form-group.has-timepicker::after {
    content: '\f017';
}

