.table-hover {
    tr, td {
        transition: background-color 0.25s ease;
    }

    tr > td:first-child a {
        text-decoration: none;
        color: $body-color;
    }

    tbody tr:hover {
        td {
            cursor: pointer;
            background: lighten($blue, 47%);
        }
    }
}

.table {
    .btn {
        white-space: nowrap;
    }

    .hide-in-overview {
        display: none;
    }
}

.table th > a {
    color: $table-head-color;
}

.table th, .table td {
    vertical-align: middle;
}

.table-striped {
    thead th {
        border: 0;
        border-bottom: 2px solid $table-border-color;
    }
}

.table-collection {
    thead th {
        border: 0;

        label {
            margin-bottom: 0;
        }
    }
}

.table-escrow-payments {
    border: 1px solid $table-border-color !important;

    tr > td:not([data-show-in-modal]) {
        display: none;
    }

    td.hide-in-overview {
      display: table-cell;
    }

    .form-control {
        padding-right: 1rem;
        max-width: 130px;
        background: transparent;
        border-radius: 0;
    }

    .input-group {
        justify-content: flex-end;
    }

    .input-group-text {
        background-color: transparent;
        border: 0;
    }
}

.table-responsive {
    overflow-x: auto;

    > .table {
        border: 0;
        margin-bottom: 0;

        tbody > tr td:first-child {
            border-left: 0;
        }

        thead > tr > th,
        tbody > tr:last-child td {
            border-bottom: 0;
        }

        tbody > tr td:last-child {
            border-right: 0;
        }
    }
}
