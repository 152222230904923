.card-header {
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 0;
}

.card-header.show {
    border-bottom: 1px solid #c8ced3;
}

.card-header.header-border-1 {
    border: 1px solid #c8ced3;
    border-bottom: 0;
}

.card-footer {
    padding-left: 1rem;
    padding-right: 1rem;
}

.card-body {
    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }
}

.card.card-inline {
    border-bottom: 0;
    border-top: 1px solid $card-border-color;
    border-left: 1px solid $body-bg;
    border-right: 1px solid $body-bg;
    margin-left: -$card-columns-gap;
    margin-right: -$card-columns-gap;
    margin-bottom: 0;
    position: relative;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        height: 1.5rem;
        width: 2px;
        background-color: $body-bg;
        content: '';
    }

    &::before {
        left: -2px;
    }

    &::after {
        right: -2px;
    }

    .card-header {
        border-top: 1.5rem solid $body-bg;
        position: relative;

        &::before {
            position: absolute;
            top: -1px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: $card-border-color;
            content: '';
        }

        a {
            font-weight: normal;
            font-size: 0.875rem;
            font-family: $font-family-base !important;
        }
    }

    .card-body {
        padding-bottom: 0;
    }
}

.card-deck-md {
    display: flex;
    flex-direction: column;

    .card {
        margin-bottom: $card-deck-margin;
    }

    @include media-breakpoint-up(md) {
        flex-flow: row wrap;
        margin-right: -$card-deck-margin;
        margin-left: -$card-deck-margin;

        .card {
            display: flex;
            // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
            flex: 1 0 0%;
            flex-direction: column;
            margin-right: $card-deck-margin;
            margin-bottom: 0; // Override the default
            margin-left: $card-deck-margin;
        }
    }
}
