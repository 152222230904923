// Admin panel styles

.list-group {
    i {
        width: 1.1rem;
    }

    .fa-dollar-sign {
        position: relative;
        left: 3px;
    }
}

.transactions,
.escrow-payments {
    .text-truncate {
        display: inline-block;
        vertical-align: middle;

        &.w-100 {
            max-width: 100px;
        }

        &.w-200 {
            max-width: 200px;
        }
    }
}

.escrow-payments {
    .badge-sentoo {
        color: $white;
        background-color: $mint;
        border-color: darken($mint, 10%);
    }

    .badge-card {
        color: #1a1f71; // Visa Blue
        background-color: #f7b600; // Visa Gold
        border-color: darken(#f7b600, 10%);
    }
}

.w-300 {
    width: 300px;
}


// EscrowDeductions
.badge-open {
    color: $white;
    background-color: $yellow;
    border-color: darken($yellow, 10%);
}

.badge-settled {
    color: $white;
    background-color: $green;
    border-color: darken($green, 20%);
}

// Highlight.js changes for Transaction detail code block
pre code.hljs {
    padding: 0 !important;
    background-color: transparent;
}


// Improved the badge coloring
.badge {
    border: 1px solid #999;
}

.badge-success {
    border-color: darken($green, 20%);
}

.badge-warning {
    border-color: darken($yellow, 10%);
}

.badge-danger {
    border-color: darken($red, 20%);
}

.badge-primary {
    border-color: darken($primary, 10%);
}