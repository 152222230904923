.row-flex {
    display: flex;
    flex-wrap: wrap;
}

//.main-container {
//    padding-right: 15px;
//    padding-left: 15px;
//}

.main .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
}

@include media-breakpoint-up(md) {
    .col-md-full-height {
        height: 100vh;
    }
}

// Custom form/grid alignment
form {
    .card-body {
        div[id]:not(.choices__item):not(.error-message):not(.alert):not(.error-container) {
            @include make-row;

            &.form-inline {
                margin-left: 0;
                margin-right: 0;
            }

            > .alert.display-block:first-child {
                display: flex;
                flex-basis: 100%;
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        // SEN-988 prevents unwanted alignment in filter
        //.col-auto + .col-auto {
        //    padding-left:  0;
        //}
    }
}

// Special treatment for certain filters (SEN-988)
.no-wrap {
    div[id]:not(.choices__item):not(.error-message) {
        flex-wrap: nowrap !important;
    }

    form .form-control-label {
        white-space: nowrap;
    }
}

// Form group styling
form[data-is-grouped] {
    .card-body > div[id]:not(.error-message) {
        @include make-row();

        > .form-group {
            @include make-col-ready();

            @include media-breakpoint-up(sm) {
                &.col-12 {
                    @include make-col(6);
                }

                &.col-auto {
                    @include make-col(12);
                }
            }

            > .form-control-label {
                @include make-col(12);
                font-weight: bold;
                display: block;
                margin-bottom: 0;
                padding: 0 0 map_get($spacers, 2) 0;

                &::after {
                    display: none;
                }
            }
        }
    }
}

