body.hide-sf-toolbar {
    .sf-toolbar {
        display: none !important;
    }
}

.bg-offwhite {
    background: $offWhite !important;
}

.text-default {
    color: $body-color !important;
}

.no-border {
    border: none !important;
}

.text-underline {
    text-decoration: underline;

    @include hover-focus {
        text-decoration: none;
    }
}

.word-break-all {
    // These are technically the same, but use both
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    // This is the dangerous one in WebKit, as it breaks things wherever
    word-break: break-all;
    // Instead use this non-standard one:
    word-break: break-word;
}

.b-r-0 {
    border-right: 0 !important;
}
.b-l-0 {
    border-left: 0 !important;
}

.bounce {
    animation: bounce 1.5s ease;
    animation-delay: 2s;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
